<template>
  <div class="filter-row">
    <button class="btn btn-secondary btn-filter" @click="toggleFilterList" v-if="advancedSearch">
      <i class="fas fa-filter"></i>
      Filter
    </button>
    <div class="filter-list" v-if="displayFilter">
      <slot></slot>
    </div>
    <div class="text-search">
      <div class="search-prefix"><i class="fas fa-search"></i></div>
      <input type="text" :placeholder="placeholder" class="search-input form-group" v-model="textSearchValue" @blur="onSearchBlur" @focus="onSearchFocus"/>
    </div>
    <div class="btn btn-primary btn-search" @click="startSearch">
      Search
    </div>
    <div class="btn btn-primary btn-reset" v-if="isSearching" @click="stopSearch">
      Reset
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemFilter',
  props: {
    eventName: String,
    placeholder: String,
    advancedSearch: {
      type: Boolean,
      default: true,
    }
  },
  data(){
    return {
      displayFilter: false,
      isSearching: false,
      textSearchValue: '',
    };
  },
  methods: {
    toggleFilterList(){
      if(this.displayFilter){
        this.displayFilter = false;
      }else{
        this.displayFilter = true;
      }
    },
    stopSearch(){
      this.$emit('reset');
      this.isSearching = false;
      this.displayFilter = false;
      this.textSearchValue = '';
    },
    startSearch(){
      this.isSearching = true;
      this.displayFilter = false;
      this.$emit('search', this.textSearchValue);
    },
    onSearchBlur(){
      $('.search-prefix').css('display', 'block');
      $('.search-input').css('padding-left', '35px');
    },
    onSearchFocus(){
      $('.search-prefix').css('display', 'none');
      $('.search-input').css('padding-left', '10px');
    },
  },
}
</script>

<style lang="scss">
.btn-filter{
  margin-bottom: 40px;
  display: block;
}

.filter-row{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;

  .filter-list{
    background-color: white;
    padding: 20px;
    width: 400px;
    position: absolute;
    left: 0;
    top: 60px;
    padding-left: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .search-input{
    margin-right: 30px;
    position: relative;
    top: 5px;
    padding-left: 35px;
  }

  .text-search{
    position: relative;
    bottom: 5px;
    .search-prefix{
      position: absolute;
      z-index: 50;
      top: 10px;
      left: 10px;
    }
    .search-input{
      min-height: 38px;
    }
  }

  .btn-filter{
    display: block;
    margin-right: 10px;
    position: relative;
    top: 12px;
  }

  .btn-reset{
    margin-bottom: 18px;
  }

  .btn-search{
    margin-bottom: 18px;
  }

  .btn-reset{
    margin-left: 10px;
  }
}
</style>
